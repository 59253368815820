import useTranslation from "next-translate/useTranslation";
import { useCallback } from "react";

import { useAppDispatch } from "stores";
import { metaUpdated } from "stores/features/meta";
import { uiUpdated } from "stores/features/ui";
import api from "utils/api";
import { DownloadBuildInput } from "utils/api/build";
import { errorMatchesMessage, getErrorStatus, handleError } from "utils/errors";

type DownloadBuildType = "public" | "private";

export const useDownloadBuild = (type: DownloadBuildType = "private") => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleDownload = useCallback(
    async (source: DownloadBuildInput) => {
      try {
        await api.build.download(source);
      } catch (e) {
        if (errorMatchesMessage(e, "not-loggedin")) {
          dispatch(
            uiUpdated({
              authModalType: "signin/claimApp",
              progressiveLogin: true,
            })
          );
          return;
        }
        if (errorMatchesMessage(e, "project-not-linked")) {
          dispatch(uiUpdated({ authModalType: "claimApp" }));
          return;
        }
        if (getErrorStatus(e) === 429) {
          if (type === "public") {
            dispatch(metaUpdated({ isShareDownloadsBlocked: true }));
          } else {
            dispatch(metaUpdated({ isBuildDownloadsBlocked: true }));
          }
        }
        handleError(e, { t });
      }
    },
    [dispatch, t, type]
  );

  return [handleDownload] as [(args: DownloadBuildInput) => Promise<void>];
};
