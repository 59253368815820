import classNames from "classnames";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import React, { useMemo, useState } from "react";

import IconAndroid from "assets/images/icons/IconAndroid";
import IconIos from "assets/images/icons/IconIos";
import IconWarning from "assets/images/icons/warning.svg";
import Button from "components/common/Button";
import { useAppSelector } from "stores";
import { BuildError } from "stores/features/appBuilder/build";
import { selectPrivateKey } from "stores/features/project";
import { selectIsSuperUser } from "stores/features/user";
import { activateSupport } from "utils/support";
import urls from "utils/urls";

import BuildErrorModal from "./BuildErrorModal";
import styles from "./styles.module.scss";

interface Props {
  error: BuildError;
  isSimulator?: boolean;
  platform: "ios" | "android";
  showPlatformLabel?: boolean;
}

const BuildErrorMessage: React.FC<Props> = ({
  error,
  isSimulator,
  platform,
  showPlatformLabel,
}) => {
  const { t } = useTranslation();
  const privateKey = useAppSelector(selectPrivateKey);
  const isSuperUser = useAppSelector(selectIsSuperUser);

  const [isExpanded, setIsExpanded] = useState(false);

  const i18nKey = useMemo(() => {
    if (error.errorCode === 0) {
      return "containers.rightPanel.preview.buildErrorTitle.internal";
    }
    if (isSuperUser) {
      return "containers.rightPanel.preview.buildErrorTitle.review";
    }
    return "containers.rightPanel.preview.buildErrorTitle.contact";
  }, [error.errorCode, isSuperUser]);

  if (typeof error !== "object") {
    return null;
  }

  return (
    <>
      <div
        className={classNames(
          styles.buildError,
          isSimulator && styles.buildErrorAppetize
        )}
      >
        {showPlatformLabel && (
          <div className={classNames(styles.buildErrorContent, styles.label)}>
            {platform === "ios" ? (
              <IconIos className={styles.buildErrorPlatform} />
            ) : (
              <IconAndroid className={styles.buildErrorPlatform} />
            )}
            {t(`common.${platform}`)}
          </div>
        )}

        <div className={styles.buildErrorContent}>
          <div className={styles.buildErrorIcon}>
            <IconWarning />
          </div>
          <div className={styles.buildErrorItem}>
            <Trans
              components={{
                contact: (
                  <Button
                    className={styles.buildErrorLink}
                    onClick={() =>
                      activateSupport({
                        buildFailureApp: urls.manage(privateKey),
                      })
                    }
                    type="link"
                  />
                ),
                report: (
                  <Button
                    className={styles.buildErrorLink}
                    onClick={() => setIsExpanded(true)}
                    type="link"
                  />
                ),
              }}
              i18nKey={t(i18nKey, {
                errorMessage: error.shortMessage,
                referenceNumber: error.referenceNumber,
              })}
            />
          </div>
        </div>
      </div>

      {isExpanded && (
        <BuildErrorModal
          isOpen
          errorMessage={error.errorMessage}
          onClose={() => setIsExpanded(false)}
          onRequestClose={() => setIsExpanded(false)}
        />
      )}
    </>
  );
};

export default BuildErrorMessage;
