import classNames from "classnames";
import { useRouter } from "next/router";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";

import ActionButton from "components/common/ActionButton";
import Button from "components/common/Button";
import InputLabel from "components/common/Input/InputLabel";
import LoadingDots from "components/common/LoadingDots";
import { useSaveProject } from "components/containers/TopBar/utils";
import ModalOutdatedConfig from "components/sections/Build/ModalOutdatedConfig";
import ContactForm from "components/sections/Services/ContactForm";
import { useAppDispatch, useAppSelector } from "stores";
import { selectBuild } from "stores/features/appBuilder/build";
import { selectLicense } from "stores/features/appBuilder/license";
import { selectEmail } from "stores/features/appBuilder/overview";
import { selectSupport } from "stores/features/appBuilder/support";
import { selectAppBuilder } from "stores/features/appBuilder/utils";
import { cartUpdated } from "stores/features/cart";
import { selectBuildChanges } from "stores/features/meta";
import { selectPrivateKey } from "stores/features/project";
import { uiUpdated } from "stores/features/ui";
import { SECTION } from "utils/constants";
import { errorMatchesMessage, handleError } from "utils/errors";
import {
  useIsAppBuilderChanged,
  useIsLicensed,
  useSocketIo,
} from "utils/hooks";
import { activateSupport } from "utils/support";
import urls from "utils/urls";

import IconBuild from "../images/build.svg";
import IconBuilding from "../images/building.svg";
import BuildErrorMessage from "./BuildErrorMessage";
import ProgressBar from "./ProgressBar";
import styles from "./styles.module.scss";
import {
  BuildButtonType,
  useBuildIssues,
  useIncrementalBuild,
  useValidateRegexValues,
} from "./utils";

interface Props {
  buildButtonClassName?: string;
  buildButtonContainerClassName?: string;
  className?: string;
  isRebuild?: boolean;
  isSimulatorButton?: boolean;
  label?: string;
  renderLeftItems?: React.ReactNode;
  renderRightItems?: React.ReactNode;
  type: BuildButtonType;
  wide?: boolean;
}

const BuildButton: React.FC<Props> = ({
  buildButtonClassName,
  buildButtonContainerClassName,
  className,
  isRebuild,
  isSimulatorButton,
  label,
  renderLeftItems = null,
  renderRightItems = null,
  type,
  wide,
}) => {
  const { buildProject } = useSaveProject();
  const build = useAppSelector(selectBuild);
  const dispatch = useAppDispatch();
  const privateKey = useAppSelector(selectPrivateKey);
  const appBuilder = useAppSelector(selectAppBuilder);
  const email = useAppSelector(selectEmail);
  const { t } = useTranslation();
  const license = useAppSelector(selectLicense);
  const support = useAppSelector(selectSupport);
  const isLicensed = useIsLicensed();
  const router = useRouter();
  const [isBuilding, setIsBuilding] = useState(false);
  const [isOutdatedConfigModalOpen, setIsOutdatedConfigModalOpen] =
    useState(false);
  const {
    showGoogleServicesRequiredWarning: showPluginWarning,
    showAppTrackingTransparencyError,
    showGoogleServicesJsonError,
    showOneSignalAppIdError,
    showUrlSchemeProtocolError,
  } = useBuildIssues();
  const {
    builderConfig,
    buildLabel,
    buildProgress,
    errors,
    isDisabled,
    progressLabel,
    showOutdatedWarning,
    showProgress,
  } = useIncrementalBuild({ type, isRebuild });
  const { showInvalidRegex } = useValidateRegexValues();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { socket, connectionError } = useSocketIo();
  const isAppetize = ["iosAppetize", "androidAppetize"].includes(type);
  const { isAppBuilderChanged } = useIsAppBuilderChanged();
  const hasChanges = useAppSelector(selectBuildChanges);

  const handleBuild = useCallback(async () => {
    if (isBuilding || !privateKey) return;
    setIsBuilding(true);
    const shouldUpdateNewDate = hasChanges;
    if (isAppBuilderChanged && !isOutdatedConfigModalOpen) {
      setIsBuilding(false);
      setIsOutdatedConfigModalOpen(true);
      return;
    }
    try {
      if (showGoogleServicesJsonError()) {
        router.push(
          urls.appBuilder(
            privateKey,
            SECTION.build,
            undefined,
            "google-services"
          ),
          undefined,
          {
            shallow: true,
          }
        );
        return;
      }
      if (showOneSignalAppIdError()) {
        router.push(
          urls.appBuilder(
            privateKey,
            SECTION.pushNotifications,
            undefined,
            "application-id"
          ),
          undefined,
          {
            shallow: true,
          }
        );

        return;
      }
      if (showAppTrackingTransparencyError()) {
        router.push(
          urls.appBuilder(
            privateKey,
            SECTION.permissions,
            undefined,
            "apple-att"
          ),
          undefined,
          {
            shallow: true,
          }
        );

        return;
      }

      if (showInvalidRegex()) return;
      if (showUrlSchemeProtocolError()) return;

      await buildProject({
        privateKey,
        data: {
          appBuilder,
          builderConfig,
        },
      });
      if (socket && !connectionError) {
        const newDate = new Date().toISOString();
        dispatch(uiUpdated({ localLastSavedTime: newDate }));
        if (isAppBuilderChanged || shouldUpdateNewDate) {
          socket.emit("save", privateKey, newDate);
        }
      }
      toast.dismiss();
      showPluginWarning();
    } catch (e) {
      if (errorMatchesMessage(e, "not-loggedin")) {
        dispatch(uiUpdated({ authModalType: "signin/claimApp" }));
        return;
      }
      if (errorMatchesMessage(e, "project-not-linked")) {
        dispatch(uiUpdated({ authModalType: "claimApp" }));
        return;
      }

      handleError(e, { t });
    } finally {
      setIsBuilding(false);
      setIsOutdatedConfigModalOpen(false);
    }
  }, [
    isBuilding,
    privateKey,
    hasChanges,
    isAppBuilderChanged,
    isOutdatedConfigModalOpen,
    showGoogleServicesJsonError,
    showOneSignalAppIdError,
    showAppTrackingTransparencyError,
    showPluginWarning,
    showInvalidRegex,
    showUrlSchemeProtocolError,
    buildProject,
    appBuilder,
    builderConfig,
    socket,
    connectionError,
    router,
    dispatch,
    t,
  ]);

  const buildNoteMessage = useMemo(() => {
    if (["none", "canceled", "inactive", "unpaid"].includes(support.status)) {
      if (
        license.app.subscriptionManagementLink &&
        license.app.support === "basic"
      ) {
        return "containers.rightPanel.preview.loadNewBuildNotesubscriptionManagementLink";
      } else if (license.app.status === "enterprise") {
        return "containers.rightPanel.preview.loadNewBuildNoteEnterprise";
      } else if (
        license.app.status === "fullService" ||
        license.services.assistedFullServiceConfig.status === "active" ||
        license.services.fullServiceConfig.status === "active"
      ) {
        return "containers.rightPanel.preview.loadNewBuildNoteFullService";
      } else if (license.app.status === "nonProduction") {
        return "containers.rightPanel.preview.loadNewBuildNoteAppUpdatesExpiredWithoutLink";
      } else
        return "containers.rightPanel.preview.loadNewBuildNoteWithAppUpdate";
    } else if (support.status === "appUpdatesExpired") {
      return license.app.subscriptionManagementLink
        ? "containers.rightPanel.preview.loadNewBuildNoteAppUpdatesExpired"
        : "containers.rightPanel.preview.loadNewBuildNoteAppUpdatesExpiredWithoutLink";
    } else if (support.status === "samExpired") {
      return license.app.subscriptionManagementLink
        ? "containers.rightPanel.preview.loadNewBuildNoteSamExpired"
        : "containers.rightPanel.preview.loadNewBuildNoteSamExpiredWithoutLink";
    } else return "";
  }, [
    license.app.status,
    license.app.subscriptionManagementLink,
    license.app.support,
    license.services.assistedFullServiceConfig.status,
    license.services.fullServiceConfig.status,
    support.status,
  ]);

  const handleAction = useCallback(() => {
    dispatch(
      cartUpdated({
        cart: { samUpdates: true },
        licensed: isLicensed,
      })
    );
    dispatch(
      uiUpdated({
        forceShowCart: true,
      })
    );
    dispatch(uiUpdated({ isRightPanelCollapsed: false }));
  }, [dispatch, isLicensed]);

  if (!privateKey) return null;

  return (
    <div className={className}>
      {!!label && <InputLabel>{label}</InputLabel>}

      {showProgress && (
        <div
          className={classNames(
            styles.buildContainer,
            isAppetize && styles.preview
          )}
        >
          <ProgressBar className={styles.buildProgress} value={buildProgress} />
          <div className={styles.buildContainerStatus}>
            <IconBuilding className={styles.buildContainerButtonIcon} />
            <div className={styles.buildContainerStatusLabel}>
              {progressLabel}
              <LoadingDots className={styles.buildContainerStatusDots} />
            </div>
          </div>
        </div>
      )}

      {errors.ios && (
        <BuildErrorMessage
          error={errors.ios}
          isSimulator={type === "androidAppetize" || type === "iosAppetize"}
          platform="ios"
          showPlatformLabel={type === "all"}
        />
      )}

      {errors.android && (
        <BuildErrorMessage
          error={errors.android}
          isSimulator={type === "androidAppetize" || type === "iosAppetize"}
          platform="android"
          showPlatformLabel={type === "all"}
        />
      )}

      {showOutdatedWarning && (
        <div className={classNames(styles.buildOutdated)}>
          <Trans
            components={{
              br: <br />,
            }}
            i18nKey={t("containers.rightPanel.preview.outdatedNote")}
          />
        </div>
      )}

      {!showProgress && (
        <div>
          <div
            className={classNames(
              styles.buildButtonItems,
              wide && styles.wide,
              (!!renderLeftItems || !!renderRightItems) && styles.center
            )}
          >
            {renderLeftItems}

            <ActionButton
              containerClassName={classNames(buildButtonContainerClassName)}
              action={buildLabel}
              className={classNames(
                styles.buildButton,
                wide && styles.wide,
                buildButtonClassName
              )}
              disabled={
                isDisabled ||
                isBuilding ||
                (!build.license.buildActive && !isSimulatorButton)
              }
              icon={<IconBuild />}
              loading={isBuilding}
              loaderColor="#ffffff"
              onClick={handleBuild}
              size="lg"
              type="primary"
            />

            {renderRightItems}
          </div>

          {!build.license.buildActive && type === "all" && (
            <div className={styles.buildButtonNote}>
              <Trans
                components={{
                  link: (
                    <Button
                      onClick={() => {
                        dispatch(
                          cartUpdated({
                            cart: {
                              professionalSupport: true,
                              samEnterprise: false,
                              selfSupport: true,
                            },
                            emailAnalytics: email,
                            licensed: isLicensed,
                          })
                        );
                        dispatch(
                          uiUpdated({
                            forceShowCart: true,
                          })
                        );
                        dispatch(uiUpdated({ isRightPanelCollapsed: false }));
                      }}
                      type="link"
                    />
                  ),
                  linkExternal: (
                    <a
                      className={styles.linkExternal}
                      href={license.app.subscriptionManagementLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      placeholder
                    </a>
                  ),
                  action: <Button onClick={() => handleAction()} type="link" />,
                  groove: (
                    <Button
                      onClick={() => activateSupport({ email })}
                      type="link"
                    />
                  ),
                  contact: (
                    <Button onClick={() => setIsModalOpen(true)} type="link" />
                  ),
                  br: <br className={styles.lineBreak} />,
                }}
                i18nKey={buildNoteMessage}
              />
            </div>
          )}
        </div>
      )}

      <ContactForm isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <ModalOutdatedConfig
        onClose={() => {
          setIsOutdatedConfigModalOpen(false);
          setIsBuilding(false);
        }}
        onRequestClose={() => {
          setIsOutdatedConfigModalOpen(false);
          setIsBuilding(false);
        }}
        onClick={handleBuild}
        isOpen={isOutdatedConfigModalOpen}
      />
    </div>
  );
};

export default BuildButton;
