import classNames from "classnames";
import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  intervalTime?: number;
}

const LoadingDots: React.FC<Props> = ({
  className,
  disabled,
  intervalTime = 1000,
}) => {
  const [dots, setDots] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (!disabled) {
      interval = setInterval(
        () => setDots((prevState) => (prevState === 3 ? 0 : prevState + 1)),
        intervalTime
      );
    }

    return () => clearInterval(interval);
  }, [disabled, intervalTime]);

  if (disabled) {
    return null;
  }

  return (
    <span className={classNames(styles.loadingDots, className)}>
      {".".repeat(dots)}
    </span>
  );
};

export default LoadingDots;
