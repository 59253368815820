import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React, { useEffect, useRef, useState } from "react";

import Button from "components/common/Button";
import Input from "components/common/Input";
import Modal, { ModalProps } from "components/common/Modal";

import styles from "./styles.module.scss";

interface Props extends ModalProps {
  errorMessage?: string;
}

const BuildErrorModal: React.FC<Props> = ({
  errorMessage,
  onClose,
  onRequestClose,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [modalHeight, setModalHeight] = useState<string | number>("20em");

  const tempMeasureRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const measureHeight = () => {
      if (tempMeasureRef.current) {
        const totalHeight = tempMeasureRef.current.clientHeight;
        setModalHeight(totalHeight <= 340 ? "340px" : "100%");
      }
    };

    const timeoutId = setTimeout(measureHeight, 100);

    return () => clearTimeout(timeoutId);
  }, [errorMessage]);
  return (
    <Modal
      className={classNames(
        styles.modal,
        modalHeight === "340px" && styles.modalHeight
      )}
      footer={
        <Button
          className={styles.modalFooterButton}
          onClick={onClose}
          size="lg"
          type="light"
        >
          {t("common.close")}
        </Button>
      }
      headerClassName={styles.modalHeader}
      onClose={onClose}
      onRequestClose={onRequestClose}
      title={t("containers.rightPanel.preview.errorReport")}
      {...otherProps}
    >
      <div ref={tempMeasureRef} className={styles.hiddenDiv}>
        {errorMessage}
      </div>
      <div className={styles.modalContent}>
        <Input
          className={styles.modalContentEditor}
          height={modalHeight.toString()}
          language="json"
          type="code"
          value={errorMessage || ""}
          readOnly
        />
      </div>
    </Modal>
  );
};

export default BuildErrorModal;
