import classNames from "classnames";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import QRCode from "react-qr-code";

import IconClone from "assets/images/icons/IconClone";
import IconDownload from "assets/images/icons/IconDownload";
import IconNewWindow from "assets/images/icons/IconNewWindow";
import ActionButton from "components/common/ActionButton";
import Modal, { ModalProps } from "components/common/Modal";
import Tooltip from "components/common/Tooltip";
import { useDownloadBuild } from "components/sections/Build/utils";
import { useAppSelector } from "stores";
import { defaultAppIcon } from "stores/constants";
import { selectAppIcon } from "stores/features/appBuilder/branding";
import { selectBuild } from "stores/features/appBuilder/build";
import {
  selectAppName,
  selectEmail,
} from "stores/features/appBuilder/overview";
import { selectMeta } from "stores/features/meta";
import { useCopyToClipboard, useImage, useIsLicensed } from "utils/hooks";
import urls, { toRelativeUrl } from "utils/urls";

import styles from "./styles.module.scss";

interface Props extends ModalProps {
  publicKey: string;
}

const InstallAppModal: React.FC<Props> = ({
  publicKey,
  onClose,
  ...otherProps
}) => {
  const [downloadBuild] = useDownloadBuild("public");
  const appName = useAppSelector(selectAppName);
  const appIcon = useImage(useAppSelector(selectAppIcon));
  const build = useAppSelector(selectBuild);
  const { t } = useTranslation();
  const isLicensed = useIsLicensed();
  const email = useAppSelector(selectEmail);
  const { isShareDownloadsBlocked: isDownloadsBlocked } =
    useAppSelector(selectMeta);
  const copy = useCopyToClipboard();

  if (!publicKey) return null;

  return (
    <Modal
      className={classNames(styles.modal)}
      headerTitleClassName={styles.title}
      closeButtonClassName={styles.closeButton}
      overlayClassName={styles.overlay}
      onClose={onClose}
      title={
        <div className={styles.appHeader}>
          <div className={classNames(styles.appIconContainer, styles.small)}>
            <img
              className={styles.appIcon}
              alt=""
              src={appIcon || toRelativeUrl(defaultAppIcon)}
              height={46}
              width={46}
            />
          </div>
          <div>{appName}</div>
        </div>
      }
      {...otherProps}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalDescription}>
          {t("containers.rightPanel.preview.installAppModal.description")}
        </div>
        <QRCode
          size={256}
          className={styles.qrCode}
          value={urls.installAppQrCode(publicKey)}
          viewBox="0 0 256 256"
        />

        <Tooltip
          label={t("sections.build.dailyLimitReached")}
          placement="top"
          disabled={!isDownloadsBlocked}
        >
          <ActionButton
            action={t("containers.rightPanel.preview.downloadAndroidApkButton")}
            containerClassName={styles.buttonContainer}
            className={styles.button}
            disabled={
              !publicKey ||
              !build.downloadLinks.androidLink ||
              isDownloadsBlocked
            }
            icon={<IconDownload />}
            onClick={() => {
              downloadBuild({
                privateKey: publicKey,
                type: "apk",
                eventName: "android_apk",
                leadKey: "android_apk_downloaded",
                licensed: isLicensed,
                email,
              });
            }}
            size="lg"
            target="_blank"
            type="outlined"
          />
        </Tooltip>
        <ActionButton
          action={t("containers.rightPanel.preview.copyToClipBoard")}
          containerClassName={styles.buttonContainer}
          className={styles.button}
          disabled={!publicKey}
          icon={<IconClone />}
          onClick={() => {
            copy(urls.share(publicKey, "apk"));
          }}
          size="lg"
          target="_blank"
          type="outlined"
        />
        <div className={styles.modalDescription}>
          <Trans
            components={{
              link: (
                <a
                  className={classNames(styles.linkContent)}
                  href={urls.documentation("install-android-apk")}
                  target="_blank"
                  rel="noreferrer"
                >
                  placeholder
                </a>
              ),
              br: <br />,
              icon: <IconNewWindow className={classNames(styles.iconLink)} />,
            }}
            i18nKey="containers.rightPanel.preview.installAppModal.footer"
          />
        </div>
      </div>
    </Modal>
  );
};

export default InstallAppModal;
