import React, { SVGProps } from "react";

const IconInstall: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M18 19.5V21H19.5V19.5H18Z" fill="currentColor" />
    <path d="M13.5 16.5V18H15V16.5H13.5Z" fill="currentColor" />
    <path d="M13.5 22.5H16.5V21H15V19.5H13.5V22.5Z" fill="currentColor" />
    <path d="M19.4999 16.5V19.5H20.9999V16.5H19.4999Z" fill="currentColor" />
    <path d="M21 19.5H22.5V22.5H19.5V21H21V19.5Z" fill="currentColor" />
    <path d="M19.5 15V13.5H22.5V16.5H21V15H19.5Z" fill="currentColor" />
    <path d="M18 15H16.5V18H15V19.5H18V15Z" fill="currentColor" />
    <path d="M13.5 13.5V15H16.5V13.5H13.5Z" fill="currentColor" />
    <path d="M7.5 16.5H4.5V19.5H7.5V16.5Z" fill="currentColor" />
    <path
      d="M10.5 22.5H1.5V13.5H10.5V22.5ZM3 21H9V15H3V21Z"
      fill="currentColor"
    />
    <path d="M19.5 4.5H16.5V7.5H19.5V4.5Z" fill="currentColor" />
    <path
      d="M22.5 10.5H13.5V1.5H22.5V10.5ZM15 9H21V3H15V9Z"
      fill="currentColor"
    />
    <path d="M7.5 4.5H4.5V7.5H7.5V4.5Z" fill="currentColor" />
    <path d="M10.5 10.5H1.5V1.5H10.5V10.5ZM3 9H9V3H3V9Z" fill="currentColor" />
  </svg>
);

export default IconInstall;
