import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React from "react";

import Button from "components/common/Button";

import InputLabel from "../Input/InputLabel";
import Tooltip from "../Tooltip";
import styles from "./styles.module.scss";

interface Props {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  inline?: boolean;
  inputComponent?: boolean;
  hideTooltip?: boolean;
  label?: string;
  onChange?: (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
}

const Switch: React.FC<Props> = ({
  checked,
  className,
  disabled,
  inline,
  inputComponent,
  hideTooltip,
  label,
  onChange = () => null,
}) => {
  const { t } = useTranslation();
  const handleCheck = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChange(!checked, event);
  };

  return (
    <div className={styles.switchContainer}>
      {label && (
        <InputLabel className={classNames(inline && styles.labelInline)} label>
          {label}
        </InputLabel>
      )}
      <Tooltip
        placement="bottom"
        label={checked ? t("common.disable") : t("common.enable")}
        disabled={hideTooltip}
        yOffset={4}
      >
        <Button
          className={classNames(
            styles.switch,
            { [styles.active]: checked },
            className
          )}
          disabled={disabled}
          onClick={handleCheck}
          allowOnPreviewMode={!inputComponent}
        >
          <div className={styles.toggle} />
        </Button>
      </Tooltip>
    </div>
  );
};

export default Switch;
